import React from 'react'
import * as styles from './index.module.less'
import classnames from 'classnames'

interface RecommendCardProps {
  className?: string
  title?: string
  img_url?: any
  date?: string
  authorName?: string
  desc?: string
  link: string
}
const RecommendCard: React.FC<RecommendCardProps> = ({
  className,
  title,
  img_url,
  date,
  authorName,
  desc,
  link: resource_url,
}) => {
  const handleClick = () => {
    const link = document.createElement('a')
    link.href = resource_url
    // 触发点击事件
    link.click()
  }
  return (
    <div className={classnames(styles.card, className)}>
      <div className="blog-recommended-item" onClick={handleClick}>
        <div className="blog-recommended-item-img">
          <img src={img_url} alt={title} />
        </div>
        <div className="blog-recommended-item-center">
          <div className="blog-recommended-item-msg">
            <span>{date}</span>
            {!!authorName && <span className="ml-[30px]">{authorName}</span>}
          </div>
          <div className="blog-recommended-item-title line-clamp-2">{title}</div>
          <div className="blog-recommended-item-desc line-clamp-3">{desc}</div>
        </div>
      </div>
    </div>
  )
}

export default RecommendCard
