import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'
interface ListItemProps {
  className?: string
  title?: string
  content?: string
}

const ListItem: React.FC<ListItemProps> = ({ className, title, content }) => {
  return (
    <div className={classnames(styles.item, className)}>
      {title && <span className="item-title">{title}:</span>}&nbsp;
      <span className="item-content">{content}</span>
    </div>
  )
}

export default ListItem
