import React from 'react'
import ListItem from './ListItem'
import Button from 'components/Base/Button'
import * as styles from './index.module.less'
import classnames from 'classnames'

interface AnalyticsTrackerProps {
  className?: string
  reverse?: boolean
  title: string
  desc: string
  list: Array<any>
  button_text: string
  button_href: string
  img_url: any
  add_button_text?: string
  add_button_href?: string
  link_title?: string
  link?: string
}

const AnalyticsTracker: React.FC<AnalyticsTrackerProps> = ({
  className,
  reverse,
  title,
  desc,
  list,
  button_text,
  button_href,
  img_url,
  add_button_text,
  add_button_href,
  link_title,
  link,
}) => {
  return (
    <div className={classnames('flex', styles.container, className, { 'lg:flex-row-reverse': reverse })}>
      <div className="analysis-content">
        <h3 className="analysis-content-title">{title}</h3>
        <p className="analysis-content-desc">
          {desc}
          {!!link && (
            <>
              &nbsp;
              <a className="text-[#00b784] font-[Objectivity-Medium-8]" href={link}>
                {link_title}
              </a>
              .
            </>
          )}
        </p>
        <div className="analysis-content-list">
          {list.map(({ name, content }) => {
            return <ListItem key={name} title={name} content={content} />
          })}
        </div>
        <div className="analysis-content-btn">
          <Button btnType="international" href={button_href}>
            {button_text}
          </Button>
          {!!add_button_href && (
            <Button className="lg:ml-[40px] mt-[10px] lg:mt-0" btnType="black" href={add_button_href}>
              {add_button_text}
            </Button>
          )}
        </div>
      </div>
      <div className="analysis-placeholder" />
      <div className="analysis-img">
        <img className="w-full h-full" src={img_url?.publicURL || img_url} alt={title} />
      </div>
    </div>
  )
}

export default AnalyticsTracker
