import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as styles from './index.module.less'
import classnames from 'classnames'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'

interface ComparisonBarProps {
  className?: string
  dataSource: Array<any>
  content_title_mb?: string
  icon_list: Array<any>
}
const a = new Array(4).fill(1)
const ComparisonBar: React.FC<ComparisonBarProps> = ({ className, dataSource, content_title_mb, icon_list }) => {
  const [leftData, centerData, rightData] = dataSource
  const isMb = useIsWindowWidthSmaller()

  const ComparisonBarPc = () => {
    return (
      <div className={classnames('lg:flex lg:w-[1200px]', styles.containerPc, className)}>
        <div className="flex-1">
          <div className="table-header">{leftData.name}</div>
          {leftData.list.map(({ content }) => {
            return (
              <div key={content} className="table-item">
                <div>
                  <img
                    className="table-item-img"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAmVBMVEUAAAAgLjQfLjQfLTMeLTUdLjQfLjYfLjQeLTMfLTQEy5QfLjT///8SfGSPlplG2bDQ9esFx5EeMjcZTEYbRkIcPDwHvIsKo3xIVVkbPz4dNjmJkZQHs4YMnHf5/PsGwI4Ir4MQhGgTdF8Ub1sVY1QXXFEXWE4Gw48Jq4AOkHAPimwSeGEZUkoHuIji+fN95MhE2K9zjIszg3JXPMSXAAAACnRSTlMAgPN8ZSwhwFW/BuK0KgAAAY5JREFUSMft18mSgjAQBmAV3DrqDLtsAiLus77/ww1kaHRySTp48DD/Kanyk5AusgwekLFpDJVjmOM7OpkzYqYTtLMRI2c0a5+LlqYnHE+ZVqZ8rnhzHa+UE685aWbN5HazIGTDtVljo2nEC1Lixhg1HjaNVf13Hy/K+W7M8A5/gnqWAn6HHvj1Hz8T9ksvqo562HdZHTvQwnvGc9XBBftNpIFzu8UOHVsua5PScYl2B2Scog0PZOx7iAMg4wrtEsj4jNYBGQ6KIvljExtLnEiwtcfhYSyHtTmBBG8ZE/T2ViUZ9sSnBGjDTIpDxoNfD2ShUCXZsHminPd32N+CHB/cbph+3T11VbIUMLx53e8PkET4FonaMnREwPbWFZtn1TUsZZjuFSr1BfDMhHi+OoaLgFNQx1ggTAkkzGcK41o0DJnbWTsHAhbKXWhsN0e7LbbWXpVy7fpaGPLKC8vsGbbYR2M8PtLwpTHG7eCqjnFFNW9H5q+lci5Od2QezPUP6/rXhL4XFP2rkf6lrH9+AGQUjpuwpahXAAAAAElFTkSuQmCC"
                    alt={content}
                  />
                </div>
                <div className="table-item-content">{content}</div>
              </div>
            )
          })}
        </div>
        <div className="lg:w-[326px] table-center">
          <div className="table-header table-header-center ">{centerData.name}</div>
          {centerData.list.map(({ content }, index) => {
            return (
              <div key={content} className="table-item">
                <div>
                  <img
                    className="table-item-img"
                    src={icon_list[index].img_url?.publicURL || icon_list[index].img_url}
                    alt={content}
                  />
                </div>
                <div className="table-item-content">{content}</div>
              </div>
            )
          })}
        </div>
        <div className="flex-1">
          <div className="table-header">{rightData.name}</div>
          {rightData.list.map(({ content }) => {
            return (
              <div key={content} className="table-item">
                <div>
                  <img
                    className="table-item-img"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAATlBMVEUAAAAgLjQfLjQfLTQeLTUdLjQfLjYeLTO/wMAfLjT///9vd3qPlpnQ0dHz8/OtsLFXYWUvPEKIkJNIVVn7+/sqOT48SU3e3t68vb14f4KtREf0AAAACHRSTlMAgPPAZSwhVe/TMxIAAAC+SURBVEjH7dfJDoMgEIDhKqCOS1nULu//ogVSEkNrMuP00Bj++6dGOMxcfpCSFSGpNrQRQEw0ybY1kKvb93uDpesmYgGHEsEqCM1uROfmSJTHMtqlJ7RELT2uwOd6Ui6YKuHRP+4+oXvmeOrwDRleOga+FlxwwQVnWBsLH1mjMViv8LVVI7CBnQwC2z1sWZj12awfxjmqP79hBRd8IuyO4TS4UvDjFozcjMwDvmhBcYZ11prAWVA4qxFnKeP3AmGZbR268EulAAAAAElFTkSuQmCC"
                    alt={content}
                  />
                </div>
                <div className="table-item-content">{content}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const ComparisonBarMb = () => {
    const [heights, setHeights] = useState<any>([])
    const [listHeights, setListHeights] = useState<any>([])
    const itemsRef = useRef<any[]>([])
    const listsRef = useRef<any[]>([])
    const rightRef = useRef<any>()
    const getHeights = () => {
      if (itemsRef.current.length && listsRef.current.length) {
        const newHeights = itemsRef.current.map((item: any) => item?.offsetHeight || 0)
        const newListHeights = listsRef.current.map((item: any) => item?.offsetHeight || 0)
        setHeights(newHeights)
        setListHeights(newListHeights)
      }
    }
    useEffect(() => {
      if (rightRef.current) {
        setTimeout(getHeights, 100)
      }
    }, [rightRef.current?.offsetHeight])
    return (
      <div className={classnames(styles.containerMb, className)}>
        <h3 className="header-title">{content_title_mb}</h3>
        <div className="flex comparison-content">
          <div className="table-left ">
            {centerData.list.map(({ content }, index) => {
              return (
                <div className={classnames('table-item')} key={content} style={{ height: heights[index] }}>
                  <div>
                    <img
                      className="w-[40px] h-[40px]"
                      src={icon_list[index].img_url?.publicURL || icon_list[index].img_url}
                      alt={content}
                    />
                  </div>
                  <div className="table-item-content">{content}</div>
                </div>
              )
            })}
          </div>
          <div className="table-center">
            {leftData.list.map(({ content }, index) => {
              return (
                <div className=" table-item flex flex-col" key={content} style={{ height: heights[index] }}>
                  <div className="flex-1">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAmVBMVEUAAAAgLjQfLjQfLTMeLTUdLjQfLjYfLjQeLTMfLTQEy5QfLjT///8SfGSPlplG2bDQ9esFx5EeMjcZTEYbRkIcPDwHvIsKo3xIVVkbPz4dNjmJkZQHs4YMnHf5/PsGwI4Ir4MQhGgTdF8Ub1sVY1QXXFEXWE4Gw48Jq4AOkHAPimwSeGEZUkoHuIji+fN95MhE2K9zjIszg3JXPMSXAAAACnRSTlMAgPN8ZSwhwFW/BuK0KgAAAY5JREFUSMft18mSgjAQBmAV3DrqDLtsAiLus77/ww1kaHRySTp48DD/Kanyk5AusgwekLFpDJVjmOM7OpkzYqYTtLMRI2c0a5+LlqYnHE+ZVqZ8rnhzHa+UE685aWbN5HazIGTDtVljo2nEC1Lixhg1HjaNVf13Hy/K+W7M8A5/gnqWAn6HHvj1Hz8T9ksvqo562HdZHTvQwnvGc9XBBftNpIFzu8UOHVsua5PScYl2B2Scog0PZOx7iAMg4wrtEsj4jNYBGQ6KIvljExtLnEiwtcfhYSyHtTmBBG8ZE/T2ViUZ9sSnBGjDTIpDxoNfD2ShUCXZsHminPd32N+CHB/cbph+3T11VbIUMLx53e8PkET4FonaMnREwPbWFZtn1TUsZZjuFSr1BfDMhHi+OoaLgFNQx1ggTAkkzGcK41o0DJnbWTsHAhbKXWhsN0e7LbbWXpVy7fpaGPLKC8vsGbbYR2M8PtLwpTHG7eCqjnFFNW9H5q+lci5Od2QezPUP6/rXhL4XFP2rkf6lrH9+AGQUjpuwpahXAAAAAElFTkSuQmCC"
                      alt={content}
                    />
                  </div>
                  <div className="pt-[10px]" style={{ height: listHeights[index] }}>
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAATlBMVEUAAAAgLjQfLjQfLTQeLTUdLjQfLjYeLTO/wMAfLjT///9vd3qPlpnQ0dHz8/OtsLFXYWUvPEKIkJNIVVn7+/sqOT48SU3e3t68vb14f4KtREf0AAAACHRSTlMAgPPAZSwhVe/TMxIAAAC+SURBVEjH7dfJDoMgEIDhKqCOS1nULu//ogVSEkNrMuP00Bj++6dGOMxcfpCSFSGpNrQRQEw0ybY1kKvb93uDpesmYgGHEsEqCM1uROfmSJTHMtqlJ7RELT2uwOd6Ui6YKuHRP+4+oXvmeOrwDRleOga+FlxwwQVnWBsLH1mjMViv8LVVI7CBnQwC2z1sWZj12awfxjmqP79hBRd8IuyO4TS4UvDjFozcjMwDvmhBcYZ11prAWVA4qxFnKeP3AmGZbR268EulAAAAAElFTkSuQmCC"
                      alt={content}
                    />
                  </div>
                </div>
              )
            })}
          </div>
          <div className="table-right " ref={rightRef}>
            {rightData.list.map(({ content }, index) => {
              return (
                <div className="table-item flex flex-col" key={content} ref={(el) => (itemsRef.current[index] = el)}>
                  <div className="flex-1">
                    <h4 className="table-item-title">SA</h4>
                    <div className="table-item-content">{leftData.list[index].content}</div>
                  </div>
                  <div className="flex-1 pt-[10px]" ref={(el) => (listsRef.current[index] = el)}>
                    <h4 className="table-item-title">GA4</h4>
                    <div className="table-item-content">{content}</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
  const content = useMemo(() => {
    return isMb ? <ComparisonBarMb /> : <ComparisonBarPc />
  }, [isMb])

  return <>{content}</>
}

export default ComparisonBar
