/**
 * 英文 神策分析
 */
import React, { useState, useRef } from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from 'layouts/en'
import NewBanner from 'components/EN/NewBanner'
import Seo from 'components/EN/Seo'
import Section from 'components/EN/Section'
import ComparisonBar from 'components/EN/ComparisonBar'
import FreeTrail from 'components/EN/FreeTrail'
import ScrollLogoWall from 'components/EN/ScrollLogoWall'
import SolutionTabs from 'components/EN/SolutionTabs'
import AnalyticsTracker from 'components/EN/AnalyticsTracker'
import GartnerCard from 'components/EN/GartnerCard'
import VideoPlayer from 'components/VideoPlayer'
import RecommendCard from 'components/EN/RecommendCard'
import CustomSwiper from 'components/EN/CustomSwiper'
import * as styles from './index.module.less'
import classnames from 'classnames'

interface Props {
  data: any
}
const Analysis: React.FC<PageProps<Props['data']>> = (props) => {
  const { data } = props
  const {
    productYaml: {
      seo,
      banner,
      section_01,
      section_02,
      section_03,
      section_04,
      section_05,
      section_06,
      section_07,
      section_08,
    },
  } = data
  const [videoUrl, setVideoUrl] = useState<string | null>(null)
  const curVideo = useRef<any>(null)
  const handleClickVideo = () => {
    setVideoUrl(banner.video_source)
    setTimeout(() => {
      curVideo?.current?.playVideo()
    }, 100)
  }

  const handlePlayVideo = (video_source) => {
    setVideoUrl(video_source)
    setTimeout(() => {
      curVideo?.current?.playVideo()
    }, 100)
  }
  return (
    <Layout>
      <Seo {...seo} />
      <main className={styles.analysis}>
        <NewBanner clickVideo={handleClickVideo} {...banner} />
        <Section className="px-[2rem] lg:px-0 lg:mt-[100px] mt-[50px]" title={section_01.title}>
          <CustomSwiper className={'mt-[40px]'} dataSource={section_01.dataSource} />
        </Section>
        <Section className="px-[2rem] lg:px-0 mt-[50px] lg:mt-[90px]" title={section_02.title}>
          <ComparisonBar
            className="lg:mt-[44px]"
            dataSource={section_02.dataSource}
            content_title_mb={section_02.content_title_mb}
            icon_list={section_02.icon_list}
          />
        </Section>
        <Section className="py-[60px] lg:pt-[64px] lg:pb-[94px] bg-[#1F2D3D] mt-[50px] px-[2rem] lg:px-0  ">
          <FreeTrail dataSource={section_03} />
        </Section>
        <Section className="px-[2rem] lg:px-0 lg:mt-[100px] mt-[50px]">
          <div className={styles.sectionOneTitle}>{section_04.title}</div>
          <ScrollLogoWall className={classnames('lg:mt-[40px] mt-[10px]', styles.logo_wall_en)} {...section_04} />
        </Section>
        <Section
          title={section_05.title}
          className={classnames('lg:mt-[100px] mt-[60px] px-[2rem] lg:px-0', styles.section_05)}
        >
          <div className="section_05_desc">{section_05.desc}</div>
          <SolutionTabs dataSource={section_05.dataSource} handleClickVideo={handlePlayVideo} />
        </Section>
        <Section className="lg:mt-[100px] mt-[60px] px-[2rem] lg:px-0">
          {section_06.map((item) => {
            return <AnalyticsTracker key={item.title} {...item} className="mb-[60px] lg:mb-[100px]" />
          })}
        </Section>
        <Section
          className={classnames(
            styles.section_07,
            'lg:mt-[100px] mt-[6rem] lg:pt-[100px] pt-[6rem]  bg-[rgba(4,203,148,0.1)] lg:pb-[85px] pb-[6rem]',
          )}
          title={section_07.title}
        >
          <GartnerCard {...section_07} className="lg:mt-[35px] mt-[14px]" />
        </Section>
        <Section className="lg:pb-[100px] pb-[60px] px-[2rem] lg:px-0 lg:mt-[100px] mt-[50px]" title={section_08.title}>
          <div className="lg:flex lg:w-[1200px] lg:justify-between mt-[26px] lg:mt-[44px]">
            {section_08.list.map((item) => {
              return <RecommendCard key={item.title} {...item} />
            })}
          </div>
        </Section>
      </main>
      {videoUrl && (
        <VideoPlayer
          videoOpt={{
            sources: videoUrl,
          }}
          fuRef={curVideo}
          onClose={() => setVideoUrl(null)}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    productYaml(page_name: { eq: "Sensors Analytics" }) {
      seo {
        title
        description
        keywords
        saTitle
      }
      banner {
        title
        desc
        buttons {
          button_text
          button_href
          button_type
        }
        video_img {
          publicURL
        }
      }
      section_01 {
        title
        dataSource {
          tag
          title
          desc
          list {
            name
            content
          }
          button_text
          button_href
          link_title
          link
          img_url {
            publicURL
          }
        }
      }
      section_02 {
        title
        content_title_mb
        dataSource {
          name
          list {
            content
          }
        }
        icon_list {
          img_url {
            publicURL
          }
        }
      }
      section_03 {
        left {
          img {
            publicURL
          }
        }
        right {
          title
          content
          button_text
          button_href
        }
      }
      section_04 {
        title
        logo_wall
      }
      section_05 {
        title
        desc
        dataSource {
          title
          desc
          buttons {
            button_text
            button_href
            button_source
          }
          img {
            publicURL
          }
          video_source
        }
      }
      section_06 {
        title
        desc
        list {
          name
          content
        }
        button_text
        button_href
        img_url {
          publicURL
        }
        add_button_text
        add_button_href
        reverse
      }
      section_07 {
        title
        content
        button_text
        button_type
        button_href
        widget_id
      }
      section_08 {
        title
        list {
          title
          desc
          date
          img_url
          link
        }
      }
    }
  }
`

export default Analysis
