import React, { useState, useRef, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'
import AnalyticsTracker from 'components/EN/AnalyticsTracker'
import 'swiper/swiper.less'
import * as styles from './index.module.less'
import classnames from 'classnames'

const CustomSwiper = ({ dataSource, className }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const swiperRef = useRef<SwiperCore>()

  const handleClickTab = (index: number) => {
    return () => {
      if (swiperRef.current) {
        swiperRef.current.slideTo(index) // 设置当前激活幻灯片索引
      }
    }
  }

  return (
    <div className={classnames(styles.container, className)}>
      {/* 顶部导航标签 */}
      <div className="swiper-tabs">
        {dataSource.map((item, index) => (
          <div
            key={index}
            className={`swiper-tab ${index === activeIndex ? 'active' : ''}`}
            onClick={handleClickTab(index)}
          >
            {item.tag}
          </div>
        ))}
      </div>

      {/* Swiper 内容 */}
      <Swiper
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        spaceBetween={10}
        autoHeight={true}
        autoplay={{
          delay: 15000,
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper
          swiper.slideTo(activeIndex)
        }}
      >
        {dataSource.map((item) => (
          <SwiperSlide key={item.tag}>
            <div className="swiper-slide-content">
              <AnalyticsTracker reverse {...item} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default CustomSwiper
